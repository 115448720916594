.bookingtitle1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  color: #000000;
  margin-left: 20px;
}

.cardcss1 {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.itemlables1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
