/* ci = checkin */
.ci_Title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000000;
}
.ci_bg {
  background: #e5e5e5;
}
.ci_card {
}
.ci_card_heading1 {
  font-family: "Montserrat";
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.ci_card_guide {
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  flex: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  order: 1;
  flex-grow: 0;
  margin-left: 7px;
}

.ci_card_hesading2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  opacity: 0.35;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.ci_resourceTypes {
  background: rgba(3, 178, 165, 0.1) !important;
  border-radius: 4px;
}
.ci_resourceTypes_Items {
  font-family: "Montserrat";
  display: flex;
  text-align: center !important;
  color: #03b2a5 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.ci_booking_duration {
  border: 1px solid #b3b3b3 !important;
}
