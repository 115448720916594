/* create Team = ct   */

.ct_c1 {
  background: #e5f7f6;
  border-radius: 12px;
}

.ct_c2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #39aea6;
}

.ct_c3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  color: #000000;
}

.ct_c4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  color: #000000;
}

.searchTeamcss {
  border-bottom: 1px solid #39aea6 !important;
  border-top: none !important;
  border-left: none !important;
  /* border-right: 1px solid #39aea6 !important; */
  border-right: none !important;
  border-radius: 0 !important;

  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #39aea6 !important;
}

.searchTeamcssfalse {
  border-radius: 0 !important;
  border-bottom: 1px solid red !important;
  border-top: none !important;
  border-left: none !important;
  /* border-right: 1px solid red !important; */
  border-right: none !important;

  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: red !important;
}
.teamExistcss {
  color: red;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
}

.teamLeadTag {
  /* background-color: #f99b1f; */
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 0px 10px;
  background: #f99b1f;
}

.teamNameC {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.teamNameC2 {
  color: var(--official-blue-color, #ef4050);
  /* color: #ef4050; */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;

  background-color: rgba(235, 107, 117, 0.1);
  /* opacity: 0.10000000149011612; */
  /* border-radius: 500px; */
  padding: 14px;
  border-radius: 100%;
  display: inline-block;
}

.searchTeamcss1 {
  margin-left: 10px;
  margin-top: 37px;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #39aea6 !important;
}

.teamNameC3 {
  color: var(--official-blue-color, hwb(176 22% 32%));
  /* color: #ef4050; */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;

  background-color: rgba(80, 170, 164, 0.1);
  /* opacity: 0.10000000149011612; */
  /* border-radius: 500px; */
  padding: 14px;
  border-radius: 100%;
  display: inline-block;
}
.teamNameC3 {
  color: var(--official-blue-color, hwb(176 22% 32%));
  /* color: #ef4050; */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;

  background-color: rgba(80, 170, 164, 0.1);
  /* opacity: 0.10000000149011612; */
  /* border-radius: 500px; */
  padding: 14px;
  border-radius: 100%;
  display: inline-block;
}

.teamNameC4 {
  color: var(--official-blue-color, #2da648);
  /* color: #ef4050; */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;

  background-color: rgba(59, 155, 80, 0.1);
  /* opacity: 0.10000000149011612; */
  /* border-radius: 500px; */
  padding: 14px;
  border-radius: 100%;
  display: inline-block;
}

.teamNameC5 {
  color: var(--official-blue-color, #2d0af1);
  /* color: #ef4050; */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;

  background-color: rgba(26, 12, 216, 0.123);
  /* opacity: 0.10000000149011612; */
  /* border-radius: 500px; */
  padding: 14px;
  border-radius: 100%;
  display: inline-block;
}

.testingclassssssss {
  position: absolute;
  display: flex;
  right: 2.5%;
}

.teamEmailC {
  color: #bababa;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.teamCardbg {
  border-radius: 10px;
  border: 1px solid rgba(237, 237, 237, 0.5);
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}

.ct_l1 {
  color: #888;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ct_l2 {
  border-radius: 10px;
  /* opacity: 0.20000000298023224; */
  background: #d9d9d9;
}

.ct_l3 {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ct_r1 {
  margin-right: 10px;
  /* background-color: green; */
}

.ct_l4 {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ct_discountInput {
  height: 30px;
  width: 10% !important;
  float: right !important;

  align-items: right !important;
}

.ct_chng_lead_l1 {
  margin-top: 13px !important;
  margin-left: 10px;
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.ct_chng_lead_l2 {
  margin-top: 13px !important;
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.switchercss {
  display: flex;
  margin-left: 12px;
  align-items: end !important;
  align-items: right !important;
  float: right;
}

.createpopcss_l1 {
  color: var(--text-01-body, #212529);
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sumary_l1 {
  color: #000;
  font-family: "Montserrat";
  font-size: 18.173px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.259px;
}

.sumary_l2 {
  padding: 10px;
  border-radius: 5.192px;
  border: 1.298px solid #000;
  background: #fff;
  text-align: center;

  color: #000;
  font-family: "Montserrat";
  font-size: 12.981px;
  font-style: normal;
  font-weight: 500;
}

.del_team_l1 {
  text-align: center;
  color: #000;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.del_team_l2 {
  text-align: center;
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.del_team_l3 {
  text-align: center;
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  line-height: 0px;
}

.del_team_c1 {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.05);
}

.del_team_c2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.del_team_c4 {
  border-radius: 20px;
  background: #fcebed !important;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.05);
}

.team_info_logo {
  height: 15px;
  width: 15px;
}

.del_team_c5 {
  color: #333;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.del_team_c6 {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.del_team_c7 {
  padding: 20px;
  border-radius: 10px;
  /* opacity: 0.10000000149011612; */
  background: #e032492f;
}

.del_team_c8 {
  height: 30px;
  width: 30px;
}

.add_t_m_btn {
  margin-top: 36px;
}

/* // copies from fintech  */
.inputerror {
  color: #ef4050;
  font-size: 12px;
  padding: 3px;
  display: none;
}

.formInput {
  padding: 1px;
}
.forminputsize {
  /* border: 1px solid #000000; */
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.inputs:invalid[focused="true"] + .inputerror {
  display: block;
}

.inputs:invalid[focused="true"] {
  border: 1px solid red;
}
/* invalid[focused="ture"] */

.forminputsize::placeholder {
  color: #c1bfbf !important; /* Adjust the color as needed */
}

/* end  */

.paginationButtonArr {
  border-radius: 50% !important;
  /* background-color: #03b2a5; */
  background-color: #ccc;
  opacity: 0.3;
  color: white;
  text-align: center;
  border: none !important;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

/* .paginationButtonArr:focus {
  outline: none;
  box-shadow: none;
  background-color: ;
} */
.paginationButtonArrActive {
  border-radius: 50% !important;
  background-color: #03b2a5 !important;
  border: none !important;
  color: white;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.csvButton {
  margin-top: 5px;
  margin-left: 20px;
  border: 1px solid #626262;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background-color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #626262;
}

.search-input-team {
  background-repeat: no-repeat;
  /* background-image: url("./../assets/images/team/searchLogo.svg"); */
  background-image: url("./../../../assets/images/team/searchLogo.svg"); /* Replace with the path to your search logo image */
  background-position: right center;

  background-size: 30px; /* Adjust the size of the logo */
  /* padding-right: 30px; Provide enough space for the logo on the right side */

  top: 50%;
}

.search-input-team ::-webkit-search-clear-button {
  display: none;
}

.position-relative > input[type="search"]::-webkit-search-decoration,
.position-relative > input[type="search"]::-webkit-search-cancel-button,
.position-relative > input[type="search"]::-webkit-search-results-button,
.position-relative > input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
