/* e_a = exceptionADD */

.e_a_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.e_a_subtitle {     
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.e_a_alaways {
  margin-left: 20px;
}

.modeltitlecss {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #212529;
}

.e_a_btns{
  font-family: "Montserrat";
  /* font-weight: 600; */
  font-size: 15px;
}

.e_a_Name_Hover:hover{
  cursor: text;

}

