.delIcon{
    color: red;
}
.delIcon:hover{
    color: rgb(189, 11, 11)
}

.editIcon{
    color: green;
}
.editIcon:hover{
    color: rgb(2, 59, 2);
}