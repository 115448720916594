.reportlabel1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    
}
.reportlabel2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #000000;
}

.reporttoplabel{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 23px;
color: #000000;
}