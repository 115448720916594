/* e_a = exceptionADD */

.e_a_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.e_a_subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.e_a_alaways {
  margin-left: 20px;
}

.modeltitlecss {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #212529;
}

.e_a_btns {
  font-family: "Montserrat";
  /* font-weight: 600; */
  font-size: 15px;
}

.e_a_Name_Hover:hover {
  cursor: text;
}

/* .datepickercss_exceptions {
  border: revert;
  border-radius: 5px;
  width: 90%;
  text-align: center;
} */
.datepickercss_exceptions {
  /* Add padding for better visual appearance */
  padding: 10px;
  /* Set a background color */
  background-color: #fff;
  /* Add a border */
  border: 1px solid #ccc;
  border-radius: 25px;
  /* Center-align the text */
  text-align: center;
  /* Add box shadow for a subtle elevation effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Ensure the width is responsive */
  width: 100%;
  /* Adjust the font size and color */
  font-size: 14px;
  color: #333;
}

/* Style for the focused state of the date picker */
.datepickercss_exceptions:focus {
  /* Add a box shadow to indicate focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
  /* Adjust the border color for focus */
  border-color: #007bff;
}
