.main-content {
  margin-left: 10px;
}
.active-checkbox {
  background-color: "#03B2A5";
  border-color: "#03B2A5";
}

/* Checkbox style when it's not checked */
.inactive-checkbox {
  border-color: #03b2a5; /* Your original border color */
}

.commands-column {
  margin-right: 10px;
  width: calc(80% - 10px); /* Adjust the width based on your design */
}
.editLink {
  cursor: pointer;
  color: black; /* Set the default text color */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */

  /* Change the text color and cursor on hover */
  &:hover {
    color: green;
    cursor: pointer;
  }
}

.model-close-btn {
  margin-right: 10px;
}
