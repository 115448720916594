.deleteIconeEdit {
  display: flex;
}
.hrImagesStyle {
  margin-top: 30px;
  color: grey dotted;
  min-height: 20px;
  border-radius: 100px;
  /* background: linear-gradient(90deg, #fff 2px, transparent 1%) center,
    linear-gradient(#fff 2px, transparent 1%) center, #ccc; */
  background-size: 5px 5px;
}
