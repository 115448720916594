/* packageCategory = p_c */

.p_C_button_title{
border: 1px solid  rgba(115, 117, 119, 0.233) !important;
border-radius: 30px;
color: black;
padding-left: 6px ;
background-color: rgba(115, 117, 119, 0.233);

/* border-color:; */
 
}



.first_box{
    position: relative;
    margin-right: 300px;
    
   
 
  
    /* --toastify-color-transparent: blue; */
}

.first_box > div {
    position: absolute;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    background-color:rgba(206, 212, 218,0.25) solid !important ;
    z-index: 1;


}
.first_box > div .div2 {
  
    background-color:rgba(206, 212, 218,0)  !important  ;


}

.drgdrptitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
text-align: left;
color: #000000;
}

.drgdrptitleLine{
    border: 1px solid #979797; 
}
.category_rounded_button{
    margin-left: 20px;
    margin-right: 20px;
    height: 20px;
    width: 20px;
}

.cat_loading{
    margin-left: 10px;
     max-height: 18px;
     max-width: 18px;
     color:#1cbb8c;
}

.deleteIconPackage{
    position:absolute;
    top: 0;
    right: 20px;
}

.colLength{
    font-size: 14px;
    font-weight: 200px;
    color: #555;
}


/* width */
::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }