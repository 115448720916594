.headings {
  font-family: "Montserrat";
  color: #474747;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.headings1 {
  font-family: "Montserrat";
  color: white;
  font-size: 18px;
}
.dashboardtab1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #808a98;
}
.generalcardborders {
}
.navbuttonborder {
  border-bottom: 2px #808a98;
}
/* .nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
    border-bottom: 2px #808A98 !important;
} */

/* .btn-light{
    background-color: #e82d2d;
} */
.dashboardfilter {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12.2353px;
  line-height: 12px;
  /* identical to box height, or 82% */

  letter-spacing: -0.207576px;

  color: #5a5858;
}
.dashboardactivefilter {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12.2353px;
  line-height: 12px;
  /* identical to box height, or 82% */

  letter-spacing: -0.207576px;

  color: #ffffff;
}

.amount1 {
  font-family: "Montserrat";
  font-style: "";
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}

.amount {
  font-family: "Montserrat";
  font-style: "";
  font-weight: 600;
  font-size: 18px;
  color: black;
}
.icons {
  color: #03b2a5;
}
.cardcss {
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.12) !important;
  border-radius: 5px;
}
.cardcss:hover {
  border: 2px solid #03b2a5;
}
.dashboardsecondcard {
  background-color: #03b2a5 !important;
}
.link {
  color: #000000;
}
.link:hover {
  color: #03b2a5;
}

.filterActiveBtnStyle {
  background-color: #03b2a5;
}
.filterInActiveBtnStyle {
  background-color: #ffffff;
}
.activeBtnHeading {
  color: #ffffff;
}
