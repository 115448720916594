.notificlockmargin{
/* margin-left: 27px; */
margin-left: 197px;
font-family:"Montserrat";
 /* text-align: right; */
}
.notifocationfontstyle{
    font-family: "Montserrat";
    font-weight: 18PX;
    font-size: small;
}
.revertcirclecolorcss1{
    color: black;
    font-weight: 800;
  
}
.readnotificationcss{
    font-family: "Montserrat";
    color: black;
    font-weight: 18PX;
    font-size: small;
    /* margin-left: 13px; */
    /* margin-bottom: 20px; */
    opacity: 0.5;
}

.notificationfontstyle{
    font-family: "Montserrat";
    font-style: normal;
}

.viewAllbButtonAlignment{

text-align: right;
}
.allmarkread{
    text-decoration: underline;
}


.markallarrow{
    cursor: pointer;
}

/* .notiwidth{
    width: 417px ;
} */
.dropdown-menu-lg{
    /* width: 130%; */
    width: 417px  !important;
}