.title{
   font-size: 16px;
   font-weight: 600;

}
.maintitle{
   font-size: 24px;
   font-weight: 600;
}
/* ---------------------------------------------------------Copy Input Css */

.copy-link {
   --height: 36px;
 
   display: flex;
   max-width: 100%px;
 }
 
 .copy-link-input {
   flex-grow: 1;
   padding: 0 8px;
   font-size: 14px;
   border: 1px solid #cccccc;
   border-right: none;
   outline: none;
 }
 
 .copy-link-input:hover {
   background: #eeeeee;
 }
 
 .copy-link-button {
   flex-shrink: 0;
   width: var(--height);
   height: var(--height);
   display: flex;
   align-items: center;
   justify-content: center;
   background: #dddddd;
   color: #333333;
   outline: none;
   border: 1px solid #cccccc;
   cursor: pointer;
 }
 
 .copy-link-button:hover {
   background: #cccccc;
 }