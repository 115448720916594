.invoicedetailid {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #000000;
}
.invoicedetailname {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
}
.rightaligncss {
  text-align: right;
}
.invoicestatuscss {
  color: green;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.invoiceunpaidstatuscss {
  color: rgba(190, 190, 75, 0.836);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.invoicedetailemail {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
}
.invoicesdetailslabel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #626262;
}
.invoicedetailsBD {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}
.invoicedetailsdata {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
}
.invoicesdetailtotalpay {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #626262;
}
.pkg_invoice_devider {
  background: #000000;
  opacity: 0.2;
  height: 3px !important;
}
.pkg_invoice_SubTotal_bg {
  background: #eeeeee;
}

.invoicesdetailtotalvalue {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000000;
}
.invoicesdetailbuttons {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #03b2a5;
}
.invoicerefundcss {
  color: #03b2a5;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.invoicepdfcss {
  color: #03b2a5;
}
.invoicepdfcss:hover {
  color: white;
}
.invoicesdetailbuttons:hover {
  color: white;
}

.invoicedetailfirstID {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  color: #000000;
  margin-left: 20px;
}
.accordion-button:not(.collapsed) {
  color: #4dbd9d !important;
}

.collapse.in,
.collapse {
  height: 200px;
  overflow-y: scroll;
  max-height: 200px;
}
#instrument.collapsing {
  max-height: 200px;
}

.markPaid_p1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.markPaid_p2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  display: "inline";
  align-items: center;
  text-align: center;
  color: #888888;
}

.markPaid_p3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: "inline";
  white-space: "nowrap";

  /* display: "inline" !important; */
  color: #000000;
}

.markPaid_p4 {
  font-family: "Montserrat";
  font-style: normal;

  color: #39aea6;
}

.markPaid_p5 {
  height: 37px;
  background: #ebf7f6 !important;
  /* opacity: 0.1; */
  border-radius: 6px;
  border: none !important;
}

.markPaid_p6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #888888;
}

.markPaid_p7 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: inline;
  align-items: center;
  text-align: center;

  color: #000000;
}

.custom-radio[type="radio"]:checked,
.custom-radio[type="radio"]:checked + label::before {
  background-color: #39aea6;
  border-color: #39aea6;
}
