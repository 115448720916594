/* faqs = f  */
.f_title{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */


color: #000000;
}

.f_title2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
text-align: justify;
color: #000000;
opacity: 0.35;
}
.f_edit_icon{
    display: flex;
    float: right;
}
.f_del_icon{
    display: inline-flex;
    float: right;
    margin-left: 7px;
}

.f_faqmodel{
    background: #F7F8FC;
}

.f_faq_model{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 23px;
line-height: 21px;
color: #000000;
}

.f_faq_model_title1{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
color: #000000;
}