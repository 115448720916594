/* detaildPakg : d_pkg */

.d_pkg{
    background: #F4F4F4;
    border: 1px solid #CED4DA;
border-radius: 5px;
}



.d_pkg_enitity{
text-align: center;
}
.d_pkg_inp{
    background: #FFFFFF !important;
    border-radius: 4px !important;
    border-color: black !important;
}

.d_pkg_all_inp{
    background: #FFFFFF !important;
    border-radius: 4px !important;
   
}