/* oh=operational hour */
.react-ios-time-picker-container {
background-color:white;

}
.react-ios-time-picker-input{
    width: 110px !important;

}

.oh_title{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;
color: #000000;
}

.oh_days_title{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 15px;
color: #000000;
}

.oh_subtitle{
    font-family: 'Montserrat';
font-weight: 400;
font-size: 16px;
color: #000000;
}

.oh_dayssubtitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 15px;
color: #979797;

}



