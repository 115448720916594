/* editDiscount = ed  */

.ed_label1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.ed_label2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height, or 150% */


color: #000000;
}
.ed_label3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.8); 
}
.ed_label4{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 21px;
/* or 175% */


color: rgba(0, 0, 0, 0.6);
}
.ed_right_label{
    float: right;
}

.ed_pecentage_toggle{
margin-left: 5px;
}