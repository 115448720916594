.event-title {
  font-size: 12px;
  font-weight: bold; /* Add this line */
  text-decoration: solid;
}
.date-style {
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.calendar-title-row {
  margin-bottom: 20px;
}
.calendar-main {
  margin-top: "100px";
  margin-left: "12px";
  margin-right: "12px";
}
.calendar-text-gray {
  color: rgb(86, 85, 85);
}
