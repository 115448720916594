.notificationcardcss{
    height:70px;
    padding: 23px;
}
.succesmarkcircle{

    color:green;
}

.revertcirclecolorcss{
    color: black;
    font-weight: 600;
    padding-left: 10px;
}
.revokedmarkcircle{

    color:orange;
}
.requestmarkcircle{
    color: lightskyblue;
}



.readnotificationlistingcss{
    font-family: "Montserrat";
    color: black;
    font-weight: 18PX;
    font-size: small;
    margin-left: 13px;
    opacity: 0.5;
}

.notificationfontstylelisting{
    font-family: "Montserrat";
    font-style: normal;
}