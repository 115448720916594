/* create_package = c_p */

.c_p_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  color: #000000;
}

.c_p_title2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.cp_starik {
  color: red;
  margin-left: 2px;
}

.c_p_light_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  color: #000000;
  opacity: 0.35;
  margin-top: 10px;
}

.c_p_delbtn {
  margin-left: 10px;
  margin-top: 7px;
}
