.innercards {
  border: 1px solid #b9b9b9 !important;
  border-radius: 1px;
  padding: 20px;
}
.bookingtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  color: #333333;
  font-size: 23px;
}
.alignrighttext {
  text-align: right;
}
.greencolor {
  background-color: #00bcae;
  align-items: center;

  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.fontcolor {
  color: #000000;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 15px;
  max-width: 100%; /* Adjust the maximum width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fontcolors {
  color: #000000;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
}
.greybgcolor {
  color: #9c9c9c;
}
.arrowheightwidth {
  font-size: 24px;
}
.arrowpadding {
  padding-top: 2 px !important;
}
.fontblackcolor {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  /* Size: 16px; */
}
.fontblackcolordates {
  color: #000000;
  font-style: normal;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-size: 15px;
}
.bookingdetail {
  color: #00a396;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  font-style: normal;
}
.tablescrolly {
  height: 150px;
  overflow-y: scroll;
  width: 100%;
}
.bookingdetailscss {
  color: #000000;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 19px;
  font-style: normal;
}
.printbutton {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
}
.link {
  color: #000000;
}
.link:hover {
  color: #03b2a5;
}
