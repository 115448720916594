.tablebgcolor {
  background-color: white;
}
.loctiontitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  
}
.dropdowncss{
  z-index: 1000 !important;
}

.totipalignment{
  /* align-items: left; */
 /* display: inline */
 /* padding-right: 30px; */
 /* text-align: left;
 */
 margin-right: 87px;
    margin-top: 5px;
}

.paginationitmes1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  margin-top: 5px;
}
.paginationitmes {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}
.link {
  color: #000000;
}
.link:hover {
  color: #03b2a5;
}
