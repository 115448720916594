.active-location {
  color: rgb(7, 115, 83);
}
.checked-box-styling {
  background-color: #03b2a5;
  border-color: #03b2a5;
  transform: scale(1.5);
  margin-right: 5px;
}
.unchecked-box-styling {
  border-color: #03b2a5;
  transform: scale(1.5);
  margin-right: 5px;
}
.color {
  border-color: #03b2a5;
  transform: scale(2);
  margin-right: 5px;
}
